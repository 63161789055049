<template>
  <div>
    <vx-card :is-refresh="isLoading">
      <vs-tabs alignment="fixed" v-model="activeTab">
        <vs-tab label="SPP Header"
                icon-pack="feather"
                :icon="errors.tabHeader.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabHeader.isError ? 'danger' : null">
          <TabHeader :initData="initDataTabHeader"/>
        </vs-tab>

        <vs-tab label="Detail Pekerjaan / Barang"
                icon-pack="feather"
                :icon="errors.tabPekerjaan.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabPekerjaan.isError ? 'danger' : null">
          <TabPekerjaan :initData="initDataTabPekerjaan"/>
        </vs-tab>

        <vs-tab label="Informasi Pembayaran"
                icon-pack="feather"
                :icon="errors.tabPembayaran.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabPembayaran.isError ? 'danger' : null">
          <TabPembayaran :initData="initDataTabPembayaran"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>

    <div class="vx-card mt-5" v-if="activeTab === 2">
      <div class="w-full flex justify-end">
        <vs-button :disabled="isLoading || !isAnyUnsavedChanges" icon-pack="feather" icon="icon-save" class="w-full" color="primary" @click="save">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import SpkRepository from '@/repositories/proyek/spk-repository'
import { mapState } from 'vuex'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import constant from '@/constant/constant'
import moduleSpkAdd from '@/store/modules/proyek/spk/spk-add.store'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'SpkEdit',
  components: {
    TabHeader: () => import('@/views/pages/proyek/spk/tabs/TabHeader'),
    TabPekerjaan: () => import('@/views/pages/proyek/spk/tabs/TabPekerjaan'),
    TabPembayaran: () => import('@/views/pages/proyek/spk/tabs/TabPembayaran')
  },
  mounted () {
    this.$store.commit('proyek/spkAdd/SET_ANY_UNSAVED_CHANGES', true) // enable by default
    this.getInitData()
  },
  data () {
    return {
      isLoading: false,
      initData: null, // all
      initDataTabHeader: null,
      initDataTabPekerjaan: null,
      initDataTabPembayaran: null
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.proyek.spkAdd.activeTab
      },
      set (value) {
        this.$store.commit('proyek/spkAdd/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      errors: state => state.proyek.spkAdd.errors,
      isAnyUnsavedChanges: state => state.proyek.spkAdd.isAnyUnsavedChanges,
      dataTabHeader: state => state.proyek.spkAdd.tabHeader,
      dataTabPekerjaan: state => state.proyek.spkAdd.tabPekerjaan,
      dataTabPembayaran: state => state.proyek.spkAdd.tabPembayaran
    })
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idSpk = this.$route.params.idSpk
      SpkRepository.show(idSpk)
        .then(response => {
          this.initData = response.data.data

          setTimeout(() => { // fix bug
            this.buildInitData()
          }, 100)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    save () {
      this.$store.commit('proyek/spkAdd/CLEAR_ERRORS')
      this.isLoading = true

      const params = convertToFormData({
        id_spk: this.$route.params.idSpk,
        tabHeader: this.dataTabHeader,
        tabPekerjaan: this.dataTabPekerjaan,
        tabPembayaran: this.dataTabPembayaran
      })

      SpkRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            if (errors.approver) {
              this.notifyErrorUsingDialog(errors)
            } else {
              this.$store.commit('proyek/spkAdd/SET_ERRORS', errors)
              this.notifyError('Data tidak valid, mohon periksa kembali.')
            }
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.$store.commit('proyek/spkAdd/SET_ANY_UNSAVED_CHANGES', false)
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'proyek.spk' })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    buildInitData () {
      // =====ALL FROM REMOTE===== //
      const initData = this.initData

      // =====TAB HEADER===== //
      const initDataTabHeader = _.pick(initData.header, ['no_spk', 'jenis_spk', 'id_proyek', 'id_rekanan', 'nama_rekanan', 'tgl_spk', 'tgl_awal', 'tgl_akhir', 'jenis_pekerjaan', 'lingkup_pekerjaan', 'nilai_total'])

      // =====TAB PEKERJAAN===== //
      const initDataTabPekerjaan = { items: [], deletedIds: [] }

      // create item with needed keys for component ItemsPekerjaanChooser
      const createObjectItemPekerjaan = function (it) {
        const item = {}
        item.old_id_spk_pekerjaan = it.id
        item.id = it.id_item_progress_pekerjaan
        item.kode = it.kode_item_progress_pekerjaan
        item.nama = it.nama_item_progress_pekerjaan
        item.id_properti_tipe = it.id_properti_tipe
        item.id_properti_unit = it.id_properti_unit
        item.item_group1 = it.id_item_group1 ? { id: it.id_item_group1, nama: it.nama_item_group1 } : null
        item.item_group2 = it.id_item_group2 ? { id: it.id_item_group2, nama: it.nama_item_group2 } : null
        item.item_group3 = it.id_item_group3 ? { id: it.id_item_group3, nama: it.nama_item_group3 } : null
        item.item_group4 = it.id_item_group4 ? { id: it.id_item_group4, nama: it.nama_item_group4 } : null
        item.no_urut = it.no_urut
        item.bobot = it.bobot
        item.sharing = it.sharing
        item.showEdit = false
        item.checked = false
        return item
      }

      // SPK JENIS: UNIT
      if (initDataTabHeader.jenis_spk === constant.JENIS_SPK.UNIT) {
        const propertiUnits = _.uniqBy(initData.pekerjaan, 'id_properti_unit')
        initDataTabPekerjaan.items = _.map(propertiUnits, it => {
          const itemsPekerjaanWhereIdUnit = _.filter(initData.pekerjaan, { id_properti_unit: it.id_properti_unit })
          const picked = _.pick(it, ['id_properti_unit', 'id_properti_tipe', 'no_unit', 'nama_properti_tipe'])
          return {
            uuid: uuid(),
            itemsPekerjaan: _.map(itemsPekerjaanWhereIdUnit, item => createObjectItemPekerjaan(item)),
            total_nilai: initData.pekerjaan[0].harga_total,
            ...picked
          }
        })
      }

      // SPK JENIS: NON UNIT
      if (initDataTabHeader.jenis_spk === constant.JENIS_SPK.NON_UNIT) {
        const rows = []
        const row = { // create one fake row for spk non unit
          uuid: uuid(),
          itemsPekerjaan: _.map(initData.pekerjaan, item => createObjectItemPekerjaan(item))
        }
        rows.push(row)
        initDataTabPekerjaan.items = rows
      }

      // SPK JENIS: OTHER
      if (initDataTabHeader.jenis_spk !== constant.JENIS_SPK.UNIT && initDataTabHeader.jenis_spk !== constant.JENIS_SPK.NON_UNIT) {
        initDataTabPekerjaan.items = _.map(initData.pekerjaan, item => {
          return {
            uuid: uuid(),
            id: item.id,
            id_item_pengadaan: item.id_item_pengadaan,
            nama_item_pengadaan: item.nama_item_pengadaan,
            satuan: item.satuan_item_pengadaan,
            qty: item.qty,
            harga: item.harga_satuan
          }
        })
      }

      // =====TAB PEMBAYARAN===== //
      const pembayaranHeader = _.pick(_.cloneDeep(initData.header), ['nilai_total', 'ppn_jenis', 'ppn_key', 'ppn_persen', 'ppn_jml', 'pph_nama', 'pph_key', 'pph_persen', 'pph_jml', 'is_input_termin_by_percentage'])
      pembayaranHeader.id_cara_bayar_coa_credit = initData.header.id_cara_bayar
      pembayaranHeader.deletedIds = []
      pembayaranHeader.items = _.map(initData.pembayaran, it => {
        return {
          old_id_spk_d: it.id,
          uuid: uuid(),
          ...(_.pick(it, ['no_termin', 'is_retention', 'tgl_due', 'bobot', 'persen', 'nilai_total', 'ppn_persen', 'ppn_jml', 'pph_persen', 'pph_jml', 'jml_total', 'jml_bayar', 'keterangan']))
        }
      })
      const initDataTabPembayaran = pembayaranHeader

      // set data with delay for another tab except tab header to avoid bugs
      this.initDataTabHeader = initDataTabHeader
      setTimeout(() => {
        this.initDataTabPekerjaan = initDataTabPekerjaan
        this.initDataTabPembayaran = initDataTabPembayaran
      }, 600)
    }

  },
  beforeCreate () {
    this.$store.registerModule(['proyek', 'spkAdd'], moduleSpkAdd)
  },
  beforeDestroy () {
    this.$store.commit('proyek/spkAdd/RESET_STATE')
    this.$store.unregisterModule(['proyek', 'spkAdd'])
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>
